<template>
  <div class="container">
    <img :src="projectStateImg" width="100%" />
  </div>
</template>

<script>
export default {
  name: 'ProjectState',
  data() {
    return {
      codeStateMap: {}
    }
  },
  computed: {
    projectStateImg() {
      return this.$route.query.projectStateImg
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  /* height: calc(100vh - 122px); */
  overflow: hidden;
  img {
    display: block;
  }
}
</style>
